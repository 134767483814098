import React from "react";
import ProfileIntroSection from "../ProfileIntroSection";
import ProfileListItem from "../ProfileListItem";
import EventListItem from "../EventListItem";
import Head from "../Head";
import ProfileList from "../ProfileList";
import { META } from "../../utils/constants";
import { ContentWrapper } from "../../style/shared";
import { ListsSection, ClientsList } from "./style";

export default ({ data }) => {
  const { clients, events, mentions, articles } = data;

  return (
    <ContentWrapper>
      <Head {...META.profile} image={META.common.image} />
      <ProfileIntroSection
        content={() => (
          <>
            <p>
             Interdisciplinary software engineer & solutions architect experienced in
             software engineering, cloud, DevSecOps and portfolio management. I design modern
             systems using analytical data-driven methods to give users an enriched experience 
             with machines. An Alumnus of University of Birmingham. I enjoy{" "}
              <a
                href="https://github.com/anvil008"
                target="_blank"
                rel="noopener noreferrer"
              >
                experimenting, building and trying
              </a>{" "}
              out new products.
            </p>
            <p>
              Sometimes I{" "}
              <a
                href="https://twitter.com/anvil008"
                target="_blank"
                rel="noopener noreferrer"
              >
                tweet
              </a>{" "}
              and share{" "}
              <a
                href="https://www.instagram.com/anvil008/"
                target="_blank"
                rel="noopener noreferrer"
              >
                photos
              </a>
              . 
            </p>
          </>
        )}
      />
      <ListsSection>
        {events.edges.length > 0 && (
          <ProfileList
            title="Timeline"
            list={() =>
              events.edges.map(({ event }, i) => (
                <EventListItem key={i} {...event} />
              ))
            }
          />
        )}
        {/* {articles.edges.length > 0 &&
            <ProfileList
              title='Public speaking & Articles'
              list={() => articles.edges.map(({ article }, i) => (
                <ProfileListItem
                  key={i}
                  {...article}
                />
              ))}
            />}
          {mentions.edges.length > 0 &&
            <ProfileList
              title='Featured on'
              list={() => mentions.edges.map(({ mention }, i) => (
                <ProfileListItem
                  key={i}
                  {...mention}
                />
              ))}
            />}
          {clients.edges.length > 0 &&
            <ClientsList
              title='Companies I have worked with'
              list={() => clients.edges.map(({ client }, i) => (
                <ProfileListItem
                  key={i}
                  {...client}
                />
              ))}
            />} */}
      </ListsSection>
    </ContentWrapper>
  );
};
