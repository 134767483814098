import React from "react";
import Intro from "../ProfileIntro";
import { Wrapper, InfoWrapper, Content, StyledImage } from "./style";
import { Loader } from "../../style/shared";

const ProfileIntroSection = ({ content }) => (
  <Wrapper>
    <InfoWrapper>
      <Intro fixed={false} />
      <Content>{content()}</Content>
    </InfoWrapper>
    <StyledImage
      src="/images/profile/anvil-palamattam-portrait.jpg"
      alt="Anvil Palamattam - Software Architect"
      loader={({ isLoaded }) => <Loader isLoaded={isLoaded} />}
      sources={[
        {
          media: "max-width: 40rem",
          srcset: [
            "/images/profile/anvil-palamattam-square.jpg 1x",
            "/images/profile/anvil-palamattam-square.jpg 2x",
            "/images/profile/anvil-palamattam-square.jpg 3x",
          ],
        },
        {
          srcset: [
            "/images/profile/anvil-palamattam-portrait.jpg 1x",
            "/images/profile/anvil-palamattam-portrait.jpg 2x",
            "/images/profile/anvil-palamattam-portrait.jpg 3x",
          ],
        },
      ]}
    />
  </Wrapper>
);

export default ProfileIntroSection;
