import React from "react";
import { Wrapper, Title } from "./style";

const Intro = (props) => (
  <Wrapper {...props}>
    <Title>
      Anvil Palamattam,
      <span> BSc (Birm) MBCS </span>
    </Title>
  </Wrapper>
);

export default Intro;
